export const seo = {
  url: 'kontakt',
  title: {
    pl: 'Kontakt',
    en: 'Contact',
    ua: 'Контакт',
  },

  desc: {
    pl: `Jesteśmy do Twojej dyspozycji. Skorzystaj z formularza lub wybierz kontakt bezpośrednio do oddziału.`,
    en: `We are at your disposal. Use the form or choose direct contact to the branch.`,
    ua: `Ми у вашому розпорядженні. Скористайтеся формою або виберіть контакт безпосередньо до відділення.`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'transport',
    'spedycja',
    'logistyka',
    'kontakt',
  ],
}

export const intro = {
  title: {
    pl: 'Kontakt',
    en: 'Contact',
    ua: 'Контакт',
  },
  desc: {
    pl: `Jesteśmy do Twojej dyspozycji. Skorzystaj z formularza lub wybierz kontakt bezpośrednio do oddziału.`,
    en: `We are at your disposal. Use the form or choose direct contact to the branch.`,
    ua: `Ми у вашому розпорядженні. Скористайтеся формою або виберіть контакт безпосередньо до відділення.`,
  },
}

export const main = {
  title: {
    pl: 'Skontaktuj się z odpowiednim oddziałem',
    en: 'Contact the appropriate branch',
    ua: 'Зверніться до відповідного відділення',
  },
  desc: {
    pl: 'Każdego dnia nasi eksperci odpowiadają na zapytania naszych Klientów. Skorzystaj z szybkiego zapytania. Twój formularz zostanie przekazany do odpowiedniej osoby i uzyskasz odpowiedź tak szybko, jak będzie to możliwe.',
    en: "Every day our experts can answer your inquiries, prepare an offer and transport any cargo. If you don't know where to turn, use the quick inquiry. Your form will be forwarded to the appropriate person and you will get a reply as soon as possible.",
    ua: "Кожен день наші фахівці відповідають на запити наших клієнтів. Скористайтеся швидким запитом. Ваша форма буде передана відповідній особі, і ви отримаєте відповідь якомога швидше.",
  },
}

export const branches = [
  {
    q: {
      pl: 'SIEDZIBA GŁÓWNA',
      en: 'HEADQUATERS',
      ua: 'головне управління',
    },
    a: `
  Omida Sea And Air S.A.<br/>
  ul. Plac Kaszubski 8<br/>
  81-350 Gdynia<br/>
  NIP: 5862288993<br/>
  REGON: 222021349<br/>
  KRS: 0000796573<br/>
  `,
    button: {
      link: 'https://g.page/r/CU5TpOhwmsOHEBA',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ WARSZAWA',
      en: 'WARSAW OFFICE',
      ua: 'ВАРШАВСЬКА ФІЛІЯ',
    },
    a: `
    ul. Sabały 58/18<br/>
    02-174 Warszawa<br/>
    tel: +48 571 406 308<br/>
  `,
    button: {
      link: 'https://g.page/r/Cad4jq7JAAu4EBA',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ KRAKÓW',
      en: 'CRACOW OFFICE',
      ua: 'ФІЛІЯ КРАКІВ',
    },
    a: `
    ul. Józefa Marcika 4A<br/>
    30-443 Kraków<br/>
    tel: +48 12 398 44 09<br/>
  `,
    button: {
      link: 'https://goo.gl/maps/3RkZYq48pBcjBNkA7',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ KATOWICE',
      en: 'KATOWICE OFFICE',
      ua: 'КАТОВІЦЕ ФІЛІЯ',
    },
    a: `
    ul. Porcelanowa 19<br/>
    40-246 Katowice<br/>
    tel: +48 690 662 831<br/>
  `,
    button: {
      link: 'https://goo.gl/maps/7xpWiKM1vYuHsiMz5',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ SŁUBICE',
      en: 'SŁUBICE OFFICE',
      ua: 'ФІЛІЯ СЛУБІЦЕ',
    },
    a: `
    ul. Piłsudskiego 8B/1<br/>
    69-100 Słubice<br/>
    tel: +48 571 406 339<br/>
  `,
    button: {
      link: 'https://g.page/r/CfPAKdcDGec5EBA',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ WROCŁAW',
      en: 'WROCLAW OFFICE',
      ua: 'ФІЛІЯ У ВРОЦЛАВІ',
    },
    a: `
    ul. Ostrowskiego 9, lok. 210<br/>
    53-238 Wrocław<br/>
    tel: +48 571 229 572<br/>
  `,
    button: {
      link: 'https://goo.gl/maps/TsNQstkJunL8nNNM6',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
  {
    q: {
      pl: 'ODDZIAŁ PYRZOWICE',
      en: 'PYRZOWICE OFFICE',
      ua: 'ФІЛІЯ ПИЖОВІЦЕ',
    },
    a: `
    ul. Centralna 5, pok. 118<br/>
    42-625 Pyrzowice<br/>
    tel: +48 516 377 008<br/>
  `,
    button: {
      link: 'https://maps.app.goo.gl/62cFmratz7KAL4nMA',
      text: {
        pl: 'Zobacz w Google Maps',
        en: 'Open Google Maps',
        ua: 'Відкрийте Google Карти',
      },
    },
  },
]
